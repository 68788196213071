<template>
    <div>
        <div class="mt-5" v-if="projects.length && dashboardHolders.length">
            <label :class="{ 'disabled': shouldDisableActions }">
                <input type="checkbox" v-model="useFixedTableHeight" @change="updateTableHeight" @keydown.tab.prevent :disabled="shouldDisableActions">
                {{ translate('use_fixed_table_height') }}
            </label>
        </div>

        <div class="mt-2" v-if="projects.length && dashboardHolders.length">
            <div class="table-wrap mt-2" ref="tableWrap">
                <table class="table table-bordered">
                    <tr>
                        <th>{{ translate('project') }}</th>
                        <th>{{ translate('holder') }}</th>
                        <th>{{ translate('holder_checked') }}</th>
                        <th>{{ translate('holder_notified') }}</th>
                        <th>{{ translate('hoder_registered') }}</th>
                    </tr>
                    <tr v-for="project in projects" :key="project.id">
                        <td>
                            <a :href="`${crmProjectUrl}${project.id}`" target="_blank" rel="noopener">
                                <strong>{{ project.name }}</strong>
                            </a>
                        </td>
                        <td v-if="isEditing(project.id)">
                            <select class="form-control" title="Verfüger" ref="holderSelect" v-model="selectedHolderId">
                                <option v-for="holder in dashboardHolders" :key="holder.id" :value="holder.id">
                                    {{ holder.isCompany ? holder.companyName : `${holder.firstName} ${holder.lastName}` }} - {{ holder.email }} ({{ holder.id }})
                                </option>
                            </select>
                        </td>
                        <td v-else-if="getHolder(project.holderId)">
                            <a :href="`${crmHolderUrl}${project.holderId}`" target="_blank" rel="noopener">
                                <strong>{{ translate('id') }}: {{ project.holderId }}</strong><br>
                                <span v-if="getHolder(project.holderId).isCompany">
                                    {{ getHolder(project.holderId).companyName }}<br>
                                </span>
                                {{ getHolder(project.holderId).firstName }} {{ getHolder(project.holderId).lastName }}<br>
                                {{ getHolder(project.holderId).email }}
                            </a>
                            <br>
                            <button class="btn btn-sm btn-primary" @click="editHolder(project.id)" :disabled="shouldDisableActions">
                                {{ translate('edit') }}
                            </button>
                        </td>
                        <td v-else class="bg-danger">
                            {{ translate('no_holder_assigned') }}
                            <br>
                            <button class="btn btn-sm btn-primary" @click="editHolder(project.id)" :disabled="shouldDisableActions">
                                {{ translate('edit') }}
                            </button>
                        </td>
                        <td v-if="getHolder(project.holderId)">
                            <span v-if="project.holderIsChecked">
                                <i class="fa fa-check text-success" aria-hidden="true"></i> {{ translate('yes') }}
                            </span>
                            <span v-else>
                                <i class="fa fa-times text-danger" aria-hidden="true"></i> {{ translate('no') }}
                            </span>
                        </td>
                        <td v-else class="bg-danger">
                            {{ translate('no_holder_assigned') }}
                        </td>
                        <td v-if="getHolder(project.holderId)">
                            <span v-if="getHolder(project.holderId).hasReceivedNotification">
                                <i class="fa fa-check text-success" aria-hidden="true"></i> {{ translate('yes') }}<br>
                                <button class="btn btn-sm btn-primary" @click="triggerRegistrationNotification(project.holderId)" :disabled="shouldDisableActions">
                                    {{ translate('notify_again') }}
                                </button>
                            </span>
                            <span v-else>
                                <i class="fa fa-times text-danger" aria-hidden="true"></i> {{ translate('no') }}<br>
                                <button class="btn btn-sm btn-primary" @click="triggerRegistrationNotification(project.holderId)" :disabled="shouldDisableActions">
                                    {{ translate('notify') }}
                                </button>
                            </span>
                        </td>
                        <td v-else class="bg-danger">
                            {{ translate('no_holder_assigned') }}
                        </td>
                        <td v-if="getHolder(project.holderId)">
                            <span v-if="getHolder(project.holderId).isRegistered">
                                <i class="fa fa-check text-success" aria-hidden="true"></i> {{ translate('yes') }}
                            </span>
                            <span v-else>
                                <i class="fa fa-times text-danger" aria-hidden="true"></i> {{ translate('no') }}
                            </span>
                        </td>
                        <td v-else class="bg-danger">
                            {{ translate('no_holder_assigned') }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import jQuery from 'jquery'

let controller;

export default {
    data () {
        return {
            projects: [],
            dashboardHolders: [],
            isFetchingProjects: false,
            isFetchingDashboardHolders: false,
            isUpdatingProject: false,
            isUpdatingDashboardHolder: false,
            editingProjectId: null,
            selectedHolderId: null,
            useFixedTableHeight: false,
            tableHeight: 'auto'
        }
    },

    computed: {
        crmBaseUrl () {
            return window.appIsProduction
                ? 'https://km.dagobertinvest.com/crm/'
                : 'https://km73dev.dagobertinvest.com/crm/';
        },

        crmProjectUrl () {
            return `${this.crmBaseUrl}shop/shop/editProduct/`;
        },

        crmHolderUrl() {
            return `${this.crmBaseUrl}kunden_s/show/`;
        },

        isFetching () {
            return this.isFetchingProjects || this.isFetchingDashboardHolders;
        },

        isUpdating () {
            return this.isUpdatingProject || this.isUpdatingDashboardHolder;
        },

        isEditingProject () {
            return this.editingProjectId !== null;
        },

        shouldDisableActions () {
            return this.isFetching || this.isUpdating || this.isEditingProject;
        }
    },

    created () {
        this.fetchProjects();
        this.fetchDashboardHolders();
    },

    mounted () {
        this.updateTableHeight();
        window.addEventListener('resize', this.updateTableHeight);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateTableHeight);
    },

    methods: {
        fetchProjects () {
            const apiUrl = '/dagoadmin/dashboard-holders/get-projects';

            controller = new AbortController();

            this.projects = [];
            this.isFetchingProjects = true;

            axios.get(apiUrl, { signal: controller.signal })
                .then(response => {
                    for (const project of response.data) {
                        this.projects.push(project);
                    }

                    this.$nextTick(() => {
                        this.updateTableHeight();
                    });
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                    }
                })
                .finally(() => {
                    this.isFetchingProjects = false;
                });
        },

        fetchDashboardHolders () {
            const apiUrl = '/dagoadmin/dashboard-holders/get-dashboard-holders';

            controller = new AbortController();

            this.dashboardHolders = [];
            this.isFetchingDashboardHolders = true;

            axios.get(apiUrl, { signal: controller.signal })
                .then(response => {
                    for (const dashboardHolder of response.data) {
                        this.dashboardHolders.push(dashboardHolder);
                    }

                    this.$nextTick(() => {
                        this.updateTableHeight();
                    });
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                    }
                })
                .finally(() => {
                    this.isFetchingDashboardHolders = false;
                });
        },

        updateAssignedDashboardHolder (projectId, holderId) {
            const apiUrl = '/dagoadmin/dashboard-holders/update-assigned-dashboard-holder';
            controller = new AbortController();
            this.isUpdatingProject = true;

            // Find the project to update
            const projectIndex = this.projects.findIndex(
                p => p.id === projectId
            );

            axios.post(apiUrl, {
                projectId,
                holderId
            }, { signal: controller.signal })
                .then(response => {
                    const success = response.data && response.data.success;

                    // Handle unsuccessful updates or project not found
                    if (!success || projectIndex === -1) {
                        this.fetchProjects();
                        window.alert(this.translate('dashboard_holders_update_error'));
                        return;
                    }

                    // If we get here, the update was successful and we found
                    // the project; we create a new object to ensure Vue
                    // reactivity.
                    const updatedProject = { ...this.projects[projectIndex] };
                    updatedProject.holderId = holderId;
                    updatedProject.holderIsChecked = true;

                    // Replace the project in the array
                    this.$set(this.projects, projectIndex, updatedProject);
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                        window.alert(this.translate('dashboard_holders_update_error'));
                        this.fetchProjects();
                    }
                })
                .finally(() => {
                    this.isUpdatingProject = false;
                });
        },

        triggerRegistrationNotification (holderId) {
            const apiUrl = '/dagoadmin/dashboard-holders/trigger-registration-notification';
            controller = new AbortController();
            this.isUpdatingDashboardHolder = true;

            // Find the holder to update
            const holderIndex = this.dashboardHolders.findIndex(
                h => h.id === holderId
            );

            axios.post(apiUrl, {
                holderId
            }, { signal: controller.signal })
                .then(response => {
                    const success = response.data && response.data.success;

                    // Handle unsuccessful updates or holder not found
                    if (!success || holderIndex === -1) {
                        this.fetchDashboardHolders();
                        window.alert(this.translate('dashboard_holders_update_error'));
                        return;
                    }

                    // If we get here, the update was successful and we found
                    // the holder; w create a new object to ensure Vue
                    // reactivity.
                    const updatedHolder = { ...this.dashboardHolders[holderIndex] };
                    updatedHolder.hasReceivedNotification = true;

                    // Replace the holder in the array
                    this.$set(this.dashboardHolders, holderIndex, updatedHolder);

                    window.alert(this.translate('dashboard_holders_notification_success'));
                })
                .catch(err => {
                    if (!axios.isCancel(err)) {
                        console.error(err);
                        window.alert(this.translate('dashboard_holders_update_error'));
                        this.fetchDashboardHolders();
                    }
                })
                .finally(() => {
                    this.isUpdatingDashboardHolder = false;
                });
        },

        getHolder (holderId) {
            return this.dashboardHolders.find(dashboardHolder => {
                return dashboardHolder.id === holderId;
            });
        },

        editHolder (projectId) {
            this.editingProjectId = projectId;

            const project = this.projects.find(p => p.id === projectId);
            this.selectedHolderId = project.holderId || null;

            // Initialize the selectpicker in the next tick after Vue has
            // updated the DOM
            this.$nextTick(() => {
                const $select = jQuery(this.$refs.holderSelect);

                $select.selectpicker({
                    liveSearch: true,
                    style: 'btn-primary',
                    width: '100%',
                });

                // Update the selectpicker to match the v-model value
                $select.selectpicker('val', this.selectedHolderId);
                $select.selectpicker('refresh');

                // Remove any existing event handlers to avoid duplicates
                $select.off('hidden.bs.select');
                $select.off('loaded.bs.select');

                // Open the dropdown once the selectpicker is fully loaded
                $select.on('loaded.bs.select', function() {
                    $select.selectpicker('toggle');
                });

                // Add a custom event that fires when the dropdown is closed;
                // this ensures the method is called even when the same value
                // is selected.
                $select.on('hidden.bs.select', () => {
                    // Only update if a holder was actually selected
                    if (this.selectedHolderId !== null) {
                        this.updateAssignedDashboardHolder(
                            projectId, this.selectedHolderId
                        );
                    }

                    // Use the $set method to ensure Vue reactivity triggers
                    // properly
                    this.$set(this, 'editingProjectId', null);
                    this.$set(this, 'selectedHolderId', null);

                    // Force Vue to update the DOM
                    this.$forceUpdate();

                    // Destroy the selectpicker to clean up
                    $select.selectpicker('destroy');
                });
            });
        },

        isEditing (projectId) {
            return this.editingProjectId === projectId;
        },

        updateTableHeight() {
            if (!this.$refs.tableWrap) {
                return;
            }

            if (!this.useFixedTableHeight) {
                this.tableHeight = 'auto';
                return;
            }

            const tableWrap = this.$refs.tableWrap;
            const rect = tableWrap.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const padding = 50; // Some bottom spacing to keep the actions in view

            this.tableHeight = `${viewportHeight - rect.top - padding}px`;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

label {
    cursor: pointer;

    &.disabled {
        cursor: initial;
        pointer-events: none;
    }
}

actions {
    line-height: 38px; // Matches the button height
}

.table-wrap {
    height: v-bind(tableHeight);
    overflow-y: auto; // We need to allow vertical scrolling since we limit the table height
}

table {
    tr {
        th,
        td {
            white-space: nowrap;
        }

        td {
            position: relative;
            text-align: left;
        }
    }
}
</style>
